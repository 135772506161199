import { RoleNameEnum } from '@@types/apiLogicTypes'
import { EditAvailableCenters } from '@components/AvailableCenters/EditAvailableCenters'
import { ChoiseCenter } from '@components/ChoiseCenter/ChoiseCenter'
import { EmployeesFormPage } from '@components/Employees/EmployeesFormPage'
import { WorkersPage } from '@components/Employees/EmployeesPage'
import { ParkScreen } from '@components/Park/ParkPage'
import { ParkPageAdd } from '@components/Park/ParkPageAdd'
import { ParkPageEdit } from '@components/Park/ParkPageEdit'
import { RootPage } from '@components/Root/RootPage'
import { AdminAddAttraction } from '@components/Settings/Admin/AdminAddAttraction'
import { AdminAddParty } from '@components/Settings/Admin/AdminAddParty'
import { AdminAddServices } from '@components/Settings/Admin/AdminAddServices'
import { AdminAttractionScreen } from '@components/Settings/Admin/AdminAttractionScreen'
import { AdminPartyScreen } from '@components/Settings/Admin/AdminPartyScreen'
import { AdminSettingsServicesScreen } from '@components/Settings/Admin/AdminServicesScreen'
import { AdminUpdateAttraction } from '@components/Settings/Admin/AdminUpdateAttraction'
import { AdminUpdateParty } from '@components/Settings/Admin/AdminUpdateParty'
import { AdminUpdateServices } from '@components/Settings/Admin/AdminUpdateServices'
import { AttractionAddScreen } from '@components/Settings/Attraction/AttractionAddScreen'
import { AttractionScreen } from '@components/Settings/Attraction/AttractionScreen'
import { AttractionUpdateScreen } from '@components/Settings/Attraction/AttractionUpdateScreen'
import { PartyAddScreen } from '@components/Settings/Party/PartyAddScreen'
import { PartyScreen } from '@components/Settings/Party/PartyScreen'
import { PartyUpdateScreen } from '@components/Settings/Party/PartyUpdateScreen'
import { RoomAddScreen } from '@components/Settings/Room/RoomAddScreen'
import { RoomScreen } from '@components/Settings/Room/RoomScreen'
import { RoomUpdateScreen } from '@components/Settings/Room/RoomUpdateScreen'
import { ServiceAddScreen } from '@components/Settings/Service/ServiceAddScreen'
import { ServiceScreen } from '@components/Settings/Service/ServiceScreen'
import { SettingsCategories } from '@components/Settings/SettingsCategories'
import { NavigationNavbar } from '@components/ui/NavigationNavbar/NavigationNavbar'
import { AdminGamesPage } from '@modules/Admin/Games/AdminGamesPage'
import { DiscountPage } from '@modules/Advertising/Discount/DiscountPage'
import { DiscountFormPage } from '@modules/Advertising/Discount/Form/DiscountFormPage'
import { LoyaltyFormPage } from '@modules/Advertising/Loyalty/Form/LoyaltyFormPage'
import { LoyaltyPage } from '@modules/Advertising/Loyalty/LoyaltyPage'
import { NewsLetters } from '@modules/Advertising/Newsletter/NewsLetters'
import { NewsletterPage } from '@modules/Advertising/Newsletter/NewsletterPage/NewsLetterPage'
import StoriesFormScreen from '@modules/Advertising/Stories/Form/StoriesFormPage'
import StoriesPage from '@modules/Advertising/Stories/StoriesPage'
import ApplicationsPage from '@modules/Applications/ApplicationsPage'
import { ConstructorPage } from '@modules/ConstructorPage/ConstructorPage'
import ContactPage from '@modules/Contacts/ContactPage/ContactPage'
import ContactsPage from '@modules/Contacts/ContactsPage/ContactsPage'
import { GamesPage } from '@modules/Games/GamesPage'
import { KassaPage } from '@modules/Integration/IntegrationPage'
import ReceptionPage from '@modules/Reception/ReceptionPage'
import SchedulePage from '@modules/Schedule/SchedulePage'
import SettingGamesPage from '@modules/Settings/Games'
import TicketsPage from '@modules/Settings/Tickets'
import TicketsFormPage from '@modules/Settings/Tickets/FormPage'
import { StaffPage } from '@modules/Staff/StaffPage'
import { StaffReports } from '@modules/StaffReports/StaffReports'
import TicketSale from '@modules/TicketSale/TicketSale'
import { TotalReport } from '@modules/TotalReport/TotalReport'
import { NavigateFunction, NavigateOptions, Outlet } from 'react-router-dom'

let navigateFunc: NavigateFunction | null = null

export const setNavigate = (navigate: NavigateFunction) => {
  navigateFunc = navigate
}

export const navigateTo = (route: string, options?: NavigateOptions) => {
  navigateFunc?.(route, options)
}

export enum AppRoutesPaths {
  Root = '/',

  Reception = '/reception',
  TicketSale = '/reception/ticket-sale',
  TicketSaleEdit = '/reception/ticket-sale/edit/:id',
  Constructor = '/reception/constructor',
  ConstructorEdit = '/reception/constructor/edit/:id',

  Advertising = '/advertising',

  AdvertisingStories = '/advertising/stories',
  AdvertisingAddStories = '/advertising/stories/add',
  AdvertisingEditStories = '/advertising/stories/update/:id',

  AdvertisingNewsletter = '/advertising/newsletter',
  AdvertisingAddNewsletter = '/advertising/newsletter/add',
  AdvertisingEditNewsletter = '/advertising/newsletter/update/:id',
  AdvertisingNewsletterArchive = '/advertising/newsletter/archive',

  AdvertisingDiscount = '/advertising/discount',
  AdvertisingAddDiscount = '/advertising/discount/add',
  AdvertisingEditDiscount = '/advertising/discount/update/:id',
  AdvertisingDiscountArchive = '/advertising/discount/archive',

  AdvertisingLoyalty = '/advertising/loyalty',
  AdvertisingLoyaltyArchive = '/advertising/loyalty/archive',

  AdvertisingAddLoyalty = '/advertising/loyalty/add',
  AdvertisingEditLoyalty = '/advertising/loyalty/update/:id',

  Tasks = '/tasks',

  Applications = '/applications',

  Schedule = '/schedule',

  Reports = '/reports',
  Payments = '/reports/payments',
  Contacts = '/reports/contacts',
  ContactEdit = '/reports/contacts/edit/:id',

  StaffReports = '/reports/employee-reports',
  TotalReport = '/reports/total-report',

  Settings = '/settings',

  SettingsServices = '/settings/sevices',
  SettingsAddService = '/settings/sevices/add',
  SettingsEditService = '/settings/sevices/edit/:id',
  SettingsArchiveService = '/settings/sevices/archive',

  SettingsRooms = '/settings/rooms',
  SettingsAddRoom = '/settings/rooms/add',
  SettingsEditRoom = '/settings/rooms/update/:id',
  SettingsArchiveRoom = '/settings/rooms/archive',

  SettingsAvailableCenters = '/settings/edit-centers',
  SettingsEditAvailableCenters = '/settings/available-centers/edit',
  SettingEmployee = '/settings/workers',
  SettingEmployeeAdd = '/settings/workers/add',
  SettingEmployeeEdit = '/settings/workers/edit/:id',

  SettingsGames = '/settings/gamezones',

  Authorization = '/authorization',

  ChoiseCenter = '/choise-center',

  AdminPanel = '/admin-panel',
  AdminPanelCategories = '/admin-panel/categories',
  AdminPanelPark = '/admin-panel/park',
  AdminPanelAddPark = '/admin-panel/park/add',
  AdminPanelEditPark = '/admin-panel/park/edit',

  AdminPanelTickets = '/admin-panel/tickets',
  AdminPanelTicketsAdd = '/admin-panel/tickets/add',
  AdminPanelTicketsUpdate = '/admin-panel/tickets/update/:id',

  AdminPanelServices = '/admin-panel/sevices',
  AdminPanelServicesAdd = '/admin-panel/sevices/add',
  AdminPanelServicesUpdate = '/admin-panel/sevices/update/:id',

  AdminPanelAttraction = '/admin-panel/attraction',
  AdminPanelAttractionAdd = '/admin-panel/attraction/add',
  AdminPanelAttractionUpdate = '/admin-panel/attraction/update/:id',

  AdminPanelParty = '/admin-panel/party',
  AdminPanelPartyAdd = '/admin-panel/party/add',
  AdminPanelPartyUpdate = '/admin-panel/party/update/:id',

  AdminPanelStories = '/admin-panel/stories',
  AdminPanelAddStories = '/admin-panel/stories/add',
  AdminPanelEditStories = '/admin-panel/stories/update/:id',

  AdminPaneNewsletter = '/admin-panel/newsletter',
  AdminPanelAdNewsletter = '/admin-panel/newsletter/add',
  AdminPanelEditNewsletter = '/admin-panel/newsletter/update/:id',

  AdminPanelDiscount = '/admin-panel/discount',
  AdminPanelAddDiscount = '/admin-panel/discount/add',
  AdminPanelEditDiscount = '/admin-panel/discount/update/:id',

  AdminPanelLoyalty = '/admin-panel/loyalty',
  AdminPanelAddLoyalty = '/admin-panel/loyalty/add',
  AdminPanelEditLoyalty = '/admin-panel/loyalty/update/:id',

  AdminPanelEmployee = '/admin-panel/workers',
  AdminPanelEmployeeAdd = '/admin-panel/workers/add',
  AdminPanelEmployeeEdit = '/admin-panel/workers/edit/:id',

  AdminPanelGames = '/admin-panel/games',

  SettingTicket = '/settings/ticket',
  SettingTicketAdd = '/settings/ticket/add',
  SettingTicketUpdate = '/settings/ticket/update/:id',
  SettingTicketArchive = '/settings/ticket/archive',

  SettingAttraction = '/settings/attraction',
  SettingAttractionAdd = '/settings/attraction/add',
  SettingAttractionUpdate = '/settings/attraction/update/:id',
  SettingAttractionArchive = '/settings/attraction/archive',

  SettingParty = '/settings/party',
  SettingPartyAdd = '/settings/party/add',
  SettingPartyUpdate = '/settings/party/update/:id',
  SettingPartyArchive = '/settings/party/archive',

  Games = '/gamepanel',

  Staff = '/staff',

  Profile = '/profile',
}
export const AdvertisingRoutes: AppRoutesType = [
  {
    name: 'Скидки',
    path: AppRoutesPaths.AdvertisingDiscount,
    element: <Outlet />,
    allowedRole: [RoleNameEnum.manager, RoleNameEnum.superadmin],
    childs: [
      {
        name: 'Скидки',
        path: AppRoutesPaths.AdvertisingDiscount,
        allowedRole: [RoleNameEnum.manager, RoleNameEnum.superadmin],
        element: <DiscountPage />,
      },
      {
        name: 'Добавить скидку',
        path: AppRoutesPaths.AdvertisingAddDiscount,
        allowedRole: [RoleNameEnum.manager, RoleNameEnum.superadmin],
        element: <DiscountFormPage />,
      },
      {
        name: 'Редактировать скидку',
        path: AppRoutesPaths.AdvertisingEditDiscount,
        allowedRole: [RoleNameEnum.manager, RoleNameEnum.superadmin],
        element: <DiscountFormPage />,
      },
      {
        name: 'Архив скидок',
        path: AppRoutesPaths.AdvertisingDiscountArchive,
        allowedRole: [RoleNameEnum.manager, RoleNameEnum.superadmin],
        element: <DiscountPage inArchive />,
      },
    ],
  },
  {
    name: 'Сторис',
    path: AppRoutesPaths.AdvertisingStories,
    element: <Outlet />,
    allowedRole: [RoleNameEnum.manager, RoleNameEnum.superadmin],
    childs: [
      {
        name: 'Сторис',
        path: AppRoutesPaths.AdvertisingStories,
        allowedRole: [RoleNameEnum.manager, RoleNameEnum.superadmin],
        element: <StoriesPage />,
      },
      {
        name: 'Добавить сторис',
        path: AppRoutesPaths.AdvertisingAddStories,
        allowedRole: [RoleNameEnum.manager, RoleNameEnum.superadmin],
        element: <StoriesFormScreen />,
      },
      {
        name: 'Редактировать сторис',
        path: AppRoutesPaths.AdvertisingEditStories,
        allowedRole: [RoleNameEnum.manager, RoleNameEnum.superadmin],
        element: <StoriesFormScreen />,
      },
    ],
  },
  {
    name: 'Программа лояльности',
    path: AppRoutesPaths.AdvertisingLoyalty,
    element: <Outlet />,
    allowedRole: [RoleNameEnum.manager, RoleNameEnum.superadmin],
    childs: [
      {
        name: 'Программа лояльности',
        path: AppRoutesPaths.AdvertisingLoyalty,
        allowedRole: [RoleNameEnum.manager, RoleNameEnum.superadmin],
        element: <LoyaltyPage />,
      },
      {
        name: 'Добавить начисление',
        path: AppRoutesPaths.AdvertisingAddLoyalty,
        allowedRole: [RoleNameEnum.manager, RoleNameEnum.superadmin],
        element: <LoyaltyFormPage />,
      },
      {
        name: 'Редактировать начисление',
        path: AppRoutesPaths.AdvertisingEditLoyalty,
        allowedRole: [RoleNameEnum.manager, RoleNameEnum.superadmin],
        element: <LoyaltyFormPage />,
      },
      {
        name: 'Архив начислений',
        path: AppRoutesPaths.AdvertisingLoyaltyArchive,
        allowedRole: [RoleNameEnum.manager, RoleNameEnum.superadmin],
        element: <LoyaltyPage inArchive />,
      },
    ],
  },
  {
    name: 'Рассылки',
    path: AppRoutesPaths.AdvertisingNewsletter,
    element: <Outlet />,
    allowedRole: [RoleNameEnum.manager, RoleNameEnum.superadmin],
    childs: [
      {
        name: 'Рассылки',
        path: AppRoutesPaths.AdvertisingNewsletter,
        allowedRole: [RoleNameEnum.manager, RoleNameEnum.superadmin],
        element: <NewsLetters />,
      },
      {
        name: 'Добавить рассылку',
        path: AppRoutesPaths.AdvertisingAddNewsletter,
        allowedRole: [RoleNameEnum.manager, RoleNameEnum.superadmin],
        element: <NewsletterPage />,
      },
      {
        name: 'Редактировать рассылку',
        path: AppRoutesPaths.AdvertisingEditNewsletter,
        allowedRole: [RoleNameEnum.manager, RoleNameEnum.superadmin],
        element: <NewsletterPage />,
      },
      {
        name: 'Архив рассылок',
        path: AppRoutesPaths.AdvertisingNewsletterArchive,
        allowedRole: [RoleNameEnum.manager, RoleNameEnum.superadmin],
        element: <NewsLetters inArchive />,
      },
    ],
  },
]

export const ReportsRoutes: AppRoutesType = [
  {
    name: 'Контакты',
    path: AppRoutesPaths.Contacts,
    element: <Outlet />,
    allowedRole: [RoleNameEnum.manager, RoleNameEnum.superadmin],
    childs: [
      {
        name: 'Контакты',
        path: AppRoutesPaths.Contacts,
        allowedRole: [RoleNameEnum.manager, RoleNameEnum.superadmin],
        element: <ContactsPage />,
      },
      {
        name: 'Редактирование контакта',
        path: AppRoutesPaths.ContactEdit,
        allowedRole: [RoleNameEnum.manager, RoleNameEnum.superadmin],
        element: <ContactPage />,
      },
    ],
  },
  {
    name: 'Касса',
    path: AppRoutesPaths.Payments,
    element: <Outlet />,
    allowedRole: [RoleNameEnum.manager, RoleNameEnum.superadmin],
    childs: [
      {
        name: 'Касса',
        path: AppRoutesPaths.Payments,
        element: <KassaPage />,
        allowedRole: [RoleNameEnum.manager, RoleNameEnum.superadmin],
      },
    ],
  },
  {
    name: 'Персонал',
    path: AppRoutesPaths.StaffReports,
    element: <StaffReports />,
    allowedRole: [RoleNameEnum.manager, RoleNameEnum.superadmin],
  },
  {
    name: 'Полный отчет',
    path: AppRoutesPaths.TotalReport,
    element: <TotalReport />,
    allowedRole: [RoleNameEnum.manager, RoleNameEnum.superadmin],
  },
]

export const SettingsRoutes: AppRoutesType = [
  {
    name: 'Услуги',
    path: AppRoutesPaths.SettingsServices,
    element: <Outlet />,
    allowedRole: [RoleNameEnum.manager, RoleNameEnum.superadmin],
    childs: [
      {
        name: 'Услуги',
        path: AppRoutesPaths.SettingsServices,
        allowedRole: [RoleNameEnum.manager, RoleNameEnum.superadmin],
        element: <ServiceScreen />,
      },
      {
        name: 'Добавить услугу',
        path: AppRoutesPaths.SettingsAddService,
        allowedRole: [RoleNameEnum.manager, RoleNameEnum.superadmin],
        element: <ServiceAddScreen />,
      },
      {
        name: 'Редактировать услугу',
        path: AppRoutesPaths.SettingsEditService,
        allowedRole: [RoleNameEnum.manager, RoleNameEnum.superadmin],
        element: <ServiceAddScreen />,
      },
      {
        name: 'Архив услуг',
        path: AppRoutesPaths.SettingsArchiveService,
        allowedRole: [RoleNameEnum.manager, RoleNameEnum.superadmin],
        element: <ServiceScreen />,
      },
    ],
  },
  {
    name: 'Сайт',
    path: AppRoutesPaths.SettingsAvailableCenters,
    element: <Outlet />,
    allowedRole: [RoleNameEnum.manager, RoleNameEnum.superadmin],
    childs: [
      {
        name: 'Сайт',
        path: AppRoutesPaths.SettingsAvailableCenters,
        element: <EditAvailableCenters />,
        allowedRole: [RoleNameEnum.manager, RoleNameEnum.superadmin],
      },
    ],
  },
  {
    name: 'Сотрудники',
    path: AppRoutesPaths.SettingEmployee,
    allowedRole: [RoleNameEnum.manager, RoleNameEnum.superadmin],
    element: <Outlet />,
    childs: [
      {
        name: 'Сотрудники',
        path: AppRoutesPaths.SettingEmployee,
        element: <WorkersPage />,
        allowedRole: [RoleNameEnum.manager, RoleNameEnum.superadmin],
      },
      {
        name: 'Добавление сотрудника',
        path: AppRoutesPaths.SettingEmployeeAdd,
        element: <EmployeesFormPage />,
        allowedRole: [RoleNameEnum.manager, RoleNameEnum.superadmin],
      },
      {
        name: 'Редактирование сотрудника',
        path: AppRoutesPaths.SettingEmployeeEdit,
        element: <EmployeesFormPage />,
        allowedRole: [RoleNameEnum.manager, RoleNameEnum.superadmin],
      },
    ],
  },
  {
    name: 'Билеты',
    path: AppRoutesPaths.SettingTicket,
    allowedRole: [RoleNameEnum.manager, RoleNameEnum.superadmin],
    element: <Outlet />,
    childs: [
      {
        name: 'Билет',
        path: AppRoutesPaths.SettingTicket,
        element: <TicketsPage />,
        allowedRole: [RoleNameEnum.manager, RoleNameEnum.superadmin],
      },
      {
        name: 'Добавление билетов',
        path: AppRoutesPaths.SettingTicketAdd,
        element: <TicketsFormPage />,
        allowedRole: [RoleNameEnum.manager, RoleNameEnum.superadmin],
      },
      {
        name: 'Редактирование билетов',
        path: AppRoutesPaths.SettingTicketUpdate,
        element: <TicketsFormPage />,
        allowedRole: [RoleNameEnum.manager, RoleNameEnum.superadmin],
      },
      {
        name: 'Архив билетов',
        path: AppRoutesPaths.SettingTicketArchive,
        element: <TicketsPage inArchive={true} />,
        allowedRole: [RoleNameEnum.manager, RoleNameEnum.superadmin],
      },
    ],
  },
  {
    name: 'Аттракцион',
    path: AppRoutesPaths.SettingAttraction,
    allowedRole: [RoleNameEnum.manager, RoleNameEnum.superadmin],
    element: <Outlet />,
    childs: [
      {
        name: 'Аттракцион',
        path: AppRoutesPaths.SettingAttraction,
        element: <AttractionScreen />,
        allowedRole: [RoleNameEnum.manager, RoleNameEnum.superadmin],
      },
      {
        name: 'Создание аттракционов',
        path: AppRoutesPaths.SettingAttractionAdd,
        element: <AttractionAddScreen />,
        allowedRole: [RoleNameEnum.manager, RoleNameEnum.superadmin],
      },
      {
        name: 'Редактирование аттракциона',
        path: AppRoutesPaths.SettingAttractionUpdate,
        element: <AttractionUpdateScreen />,
        allowedRole: [RoleNameEnum.manager, RoleNameEnum.superadmin],
      },
      {
        name: 'Архив аттракционов',
        path: AppRoutesPaths.SettingAttractionArchive,
        element: <AttractionScreen />,
        allowedRole: [RoleNameEnum.manager, RoleNameEnum.superadmin],
      },
    ],
  },
  {
    name: 'Патирумы',
    path: AppRoutesPaths.SettingsRooms,
    allowedRole: [RoleNameEnum.manager, RoleNameEnum.superadmin],
    element: <Outlet />,
    childs: [
      {
        name: 'Патирумы',
        path: AppRoutesPaths.SettingsRooms,
        allowedRole: [RoleNameEnum.manager, RoleNameEnum.superadmin],
        element: <RoomScreen />,
      },
      {
        name: 'Добавить патирум',
        path: AppRoutesPaths.SettingsAddRoom,
        allowedRole: [RoleNameEnum.manager, RoleNameEnum.superadmin],
        element: <RoomAddScreen />,
      },
      {
        name: 'Редактировать патирум',
        path: AppRoutesPaths.SettingsEditRoom,
        allowedRole: [RoleNameEnum.manager, RoleNameEnum.superadmin],
        element: <RoomUpdateScreen />,
      },
      {
        name: 'Архив патирумов',
        path: AppRoutesPaths.SettingsArchiveRoom,
        allowedRole: [RoleNameEnum.manager, RoleNameEnum.superadmin],
        element: <RoomScreen />,
      },
    ],
  },
  {
    name: 'Праздники',
    path: AppRoutesPaths.SettingParty,
    allowedRole: [RoleNameEnum.manager, RoleNameEnum.superadmin],
    element: <Outlet />,
    childs: [
      {
        name: 'Праздник',
        path: AppRoutesPaths.SettingParty,
        allowedRole: [RoleNameEnum.manager, RoleNameEnum.superadmin],
        element: <PartyScreen />,
      },
      {
        name: 'Добавление праздников',
        path: AppRoutesPaths.SettingPartyAdd,
        allowedRole: [RoleNameEnum.manager, RoleNameEnum.superadmin],
        element: <PartyAddScreen />,
      },
      {
        name: 'Редактирование праздника',
        path: AppRoutesPaths.SettingPartyUpdate,
        allowedRole: [RoleNameEnum.manager, RoleNameEnum.superadmin],
        element: <PartyUpdateScreen />,
      },
      {
        name: 'Архив праздников',
        path: AppRoutesPaths.SettingPartyArchive,
        allowedRole: [RoleNameEnum.manager, RoleNameEnum.superadmin],
        element: <PartyScreen />,
      },
    ],
  },
  {
    name: 'Игры',
    path: AppRoutesPaths.SettingsGames,
    allowedRole: [RoleNameEnum.manager, RoleNameEnum.superadmin],
    element: <SettingGamesPage />,
  },
]

export const AdminRoutes: AppRoutesType = [
  {
    name: 'Категории',
    path: AppRoutesPaths.AdminPanelCategories,
    element: <SettingsCategories />,
    allowedRole: [RoleNameEnum.manager, RoleNameEnum.superadmin],
  },
  {
    name: 'Центры',
    path: AppRoutesPaths.AdminPanelPark,
    element: <Outlet />,
    allowedRole: [RoleNameEnum.superadmin],
    childs: [
      {
        name: 'Центры',
        path: AppRoutesPaths.AdminPanelPark,
        element: <ParkScreen />,
        allowedRole: [RoleNameEnum.superadmin],
      },
      {
        name: 'Добавить Центр',
        path: AppRoutesPaths.AdminPanelAddPark,
        element: <ParkPageAdd />,
        allowedRole: [RoleNameEnum.superadmin],
      },
      {
        name: 'Изменить Центр',
        path: AppRoutesPaths.AdminPanelEditPark,
        element: <ParkPageEdit />,
        allowedRole: [RoleNameEnum.superadmin],
      },
    ],
  },
  {
    name: 'Билеты',
    path: AppRoutesPaths.AdminPanelTickets,
    element: <Outlet />,
    allowedRole: [RoleNameEnum.superadmin],
    childs: [
      {
        name: 'Билеты',
        path: AppRoutesPaths.AdminPanelTickets,
        element: <TicketsPage isAdmin={true} />,
        allowedRole: [RoleNameEnum.superadmin],
      },
      {
        name: 'Добавить билеты',
        path: AppRoutesPaths.AdminPanelTicketsAdd,
        element: <TicketsFormPage isAdmin={true} />,
        allowedRole: [RoleNameEnum.superadmin],
      },
      {
        name: 'Изменить билеты',
        path: AppRoutesPaths.AdminPanelTicketsUpdate,
        element: <TicketsFormPage isAdmin={true} />,
        allowedRole: [RoleNameEnum.superadmin],
      },
    ],
  },

  {
    name: 'Услуги',
    path: AppRoutesPaths.AdminPanelServices,
    element: <Outlet />,
    allowedRole: [RoleNameEnum.superadmin],
    childs: [
      {
        name: 'Услуги',
        path: AppRoutesPaths.AdminPanelServices,
        allowedRole: [RoleNameEnum.superadmin],
        element: <AdminSettingsServicesScreen />,
      },
      {
        name: 'Добавить услуги',
        path: AppRoutesPaths.AdminPanelServicesAdd,
        allowedRole: [RoleNameEnum.superadmin],
        element: <AdminAddServices />,
      },
      {
        name: 'Редактировать услуги',
        path: AppRoutesPaths.AdminPanelServicesUpdate,
        allowedRole: [RoleNameEnum.superadmin],
        element: <AdminUpdateServices />,
      },
    ],
  },
  {
    name: 'Аттракционы',
    path: AppRoutesPaths.AdminPanelAttraction,
    allowedRole: [RoleNameEnum.superadmin],
    element: <Outlet />,
    childs: [
      {
        name: 'Аттракционы',
        path: AppRoutesPaths.AdminPanelAttraction,
        element: <AdminAttractionScreen />,
        allowedRole: [RoleNameEnum.superadmin],
      },
      {
        name: 'Создание аттракционов',
        path: AppRoutesPaths.AdminPanelAttractionAdd,
        element: <AdminAddAttraction />,
        allowedRole: [RoleNameEnum.superadmin],
      },
      {
        name: 'Редактирование аттракционов',
        path: AppRoutesPaths.AdminPanelAttractionUpdate,
        element: <AdminUpdateAttraction />,
        allowedRole: [RoleNameEnum.superadmin],
      },
    ],
  },
  {
    name: 'Праздники',
    path: AppRoutesPaths.AdminPanelParty,
    allowedRole: [RoleNameEnum.superadmin],
    element: <Outlet />,
    childs: [
      {
        name: 'Праздники',
        path: AppRoutesPaths.AdminPanelParty,
        element: <AdminPartyScreen />,
        allowedRole: [RoleNameEnum.superadmin],
      },
      {
        name: 'Создание праздников',
        path: AppRoutesPaths.AdminPanelPartyAdd,
        element: <AdminAddParty />,
        allowedRole: [RoleNameEnum.superadmin],
      },
      {
        name: 'Редактирование праздников',
        path: AppRoutesPaths.AdminPanelPartyUpdate,
        element: <AdminUpdateParty />,
        allowedRole: [RoleNameEnum.superadmin],
      },
    ],
  },

  {
    name: 'Сторис',
    path: AppRoutesPaths.AdminPanelStories,
    element: <Outlet />,
    allowedRole: [RoleNameEnum.manager, RoleNameEnum.superadmin],
    childs: [
      {
        name: 'Сторис',
        path: AppRoutesPaths.AdminPanelStories,
        allowedRole: [RoleNameEnum.manager, RoleNameEnum.superadmin],
        element: <StoriesPage isAdmin />,
      },
      {
        name: 'Добавить сторис',
        path: AppRoutesPaths.AdminPanelAddStories,
        allowedRole: [RoleNameEnum.manager, RoleNameEnum.superadmin],
        element: <StoriesFormScreen isAdmin />,
      },
      {
        name: 'Редактировать сторис',
        path: AppRoutesPaths.AdminPanelEditStories,
        allowedRole: [RoleNameEnum.manager, RoleNameEnum.superadmin],
        element: <StoriesFormScreen isAdmin />,
      },
    ],
  },
  {
    name: 'Программа лояльности',
    path: AppRoutesPaths.AdminPanelLoyalty,
    element: <Outlet />,
    allowedRole: [RoleNameEnum.manager, RoleNameEnum.superadmin],
    childs: [
      {
        name: 'Сторис',
        path: AppRoutesPaths.AdminPanelLoyalty,
        allowedRole: [RoleNameEnum.manager, RoleNameEnum.superadmin],
        element: <LoyaltyPage isAdmin />,
      },
      {
        name: 'Добавить начисление',
        path: AppRoutesPaths.AdminPanelAddLoyalty,
        allowedRole: [RoleNameEnum.manager, RoleNameEnum.superadmin],
        element: <LoyaltyFormPage isAdmin />,
      },
      {
        name: 'Редактировать начисление',
        path: AppRoutesPaths.AdminPanelEditLoyalty,
        allowedRole: [RoleNameEnum.manager, RoleNameEnum.superadmin],
        element: <LoyaltyFormPage isAdmin />,
      },
    ],
  },
  {
    name: 'Рассылки',
    path: AppRoutesPaths.AdminPaneNewsletter,
    element: <Outlet />,
    allowedRole: [RoleNameEnum.manager, RoleNameEnum.superadmin],
    childs: [
      {
        name: 'Рассылки',
        path: AppRoutesPaths.AdminPaneNewsletter,
        allowedRole: [RoleNameEnum.manager, RoleNameEnum.superadmin],
        element: <NewsLetters isAdmin />,
      },
      {
        name: 'Добавить рассылку',
        path: AppRoutesPaths.AdminPanelAdNewsletter,
        allowedRole: [RoleNameEnum.manager, RoleNameEnum.superadmin],
        element: <NewsletterPage isAdmin />,
      },
      {
        name: 'Редактировать рассылку',
        path: AppRoutesPaths.AdminPanelEditNewsletter,
        allowedRole: [RoleNameEnum.manager, RoleNameEnum.superadmin],
        element: <NewsletterPage isAdmin />,
      },
    ],
  },
  {
    name: 'Скидки',
    path: AppRoutesPaths.AdminPanelDiscount,
    element: <Outlet />,
    allowedRole: [RoleNameEnum.manager, RoleNameEnum.superadmin],
    childs: [
      {
        name: 'Скидки',
        path: AppRoutesPaths.AdminPanelDiscount,
        allowedRole: [RoleNameEnum.manager, RoleNameEnum.superadmin],
        element: <DiscountPage isAdmin />,
      },
      {
        name: 'Добавить скидку',
        path: AppRoutesPaths.AdminPanelAddDiscount,
        allowedRole: [RoleNameEnum.manager, RoleNameEnum.superadmin],
        element: <DiscountFormPage isAdmin />,
      },
      {
        name: 'Редактировать скидку',
        path: AppRoutesPaths.AdminPanelEditDiscount,
        allowedRole: [RoleNameEnum.manager, RoleNameEnum.superadmin],
        element: <DiscountFormPage isAdmin />,
      },
    ],
  },
  {
    name: 'Управляющие',
    path: AppRoutesPaths.AdminPanelEmployee,
    element: <Outlet />,
    allowedRole: [RoleNameEnum.manager, RoleNameEnum.superadmin],
    childs: [
      {
        name: 'Управляющие',
        path: AppRoutesPaths.AdminPanelEmployee,
        allowedRole: [RoleNameEnum.manager, RoleNameEnum.superadmin],
        element: <WorkersPage isAdmin />,
      },
      {
        name: 'Добавить управляющего',
        path: AppRoutesPaths.AdminPanelEmployeeAdd,
        allowedRole: [RoleNameEnum.manager, RoleNameEnum.superadmin],
        element: <EmployeesFormPage isAdmin />,
      },
      {
        name: 'Редактировать управляющего',
        path: AppRoutesPaths.AdminPanelEmployeeEdit,
        allowedRole: [RoleNameEnum.manager, RoleNameEnum.superadmin],
        element: <EmployeesFormPage isAdmin />,
      },
    ],
  },
  {
    name: 'Игры',
    path: AppRoutesPaths.AdminPanelGames,
    element: <AdminGamesPage />,
    allowedRole: [RoleNameEnum.manager, RoleNameEnum.superadmin],
  },
]

export const NavigationMenuItems: AppRoutesType = [
  {
    name: 'Ресепшен',
    path: AppRoutesPaths.Reception,
    icon: '/icons/Reseption.svg',
    element: <Outlet />,
    allowedRole: [RoleNameEnum.default],
    childs: [
      {
        name: 'Ресепшен',
        path: AppRoutesPaths.Reception,
        allowedRole: [RoleNameEnum.manager, RoleNameEnum.superadmin],
        element: <ReceptionPage />,
      },
      {
        name: 'Продажа билетов',
        path: AppRoutesPaths.TicketSale,
        allowedRole: [RoleNameEnum.manager, RoleNameEnum.superadmin],
        element: <TicketSale />,
        childs: [
          {
            name: 'Редактирование заказа',
            path: AppRoutesPaths.TicketSaleEdit,
            allowedRole: [RoleNameEnum.manager, RoleNameEnum.superadmin],
            element: <TicketSale />,
          },
        ],
      },
      {
        name: 'Конструктор',
        path: AppRoutesPaths.Constructor,
        allowedRole: [RoleNameEnum.manager, RoleNameEnum.superadmin],
        element: <ConstructorPage />,
        childs: [
          {
            name: 'Редактирование заказа',
            path: AppRoutesPaths.ConstructorEdit,
            allowedRole: [RoleNameEnum.manager, RoleNameEnum.superadmin],
            element: <ConstructorPage />,
          },
        ],
      },
    ],
  },
  {
    name: 'Реклама',
    path: AppRoutesPaths.Advertising,
    icon: '/icons/Kassa.svg',
    element: <NavigationNavbar routes={AdvertisingRoutes} title='Реклама' />,
    childs: AdvertisingRoutes,
    allowedRole: [RoleNameEnum.manager, RoleNameEnum.superadmin],
  },
  {
    name: 'Заявки',
    path: AppRoutesPaths.Applications,
    icon: '/icons/Zayavki.svg',
    element: <ApplicationsPage></ApplicationsPage>,
    allowedRole: [RoleNameEnum.manager, RoleNameEnum.superadmin],
  },
  {
    name: 'Расписание',
    path: AppRoutesPaths.Schedule,
    icon: '/icons/Schedule.svg',
    element: <SchedulePage />,
    allowedRole: [RoleNameEnum.manager, RoleNameEnum.superadmin],
  },
  {
    name: 'Отчеты',
    path: AppRoutesPaths.Reports,
    icon: '/icons/Analytics.svg',
    element: <NavigationNavbar routes={ReportsRoutes} title='Отчеты' />,
    childs: ReportsRoutes,
    allowedRole: [RoleNameEnum.manager, RoleNameEnum.superadmin],
  },
  {
    name: 'Настройка',
    path: AppRoutesPaths.Settings,
    icon: '/icons/Settings.svg',
    element: <NavigationNavbar routes={SettingsRoutes} title='Настройка' />,
    childs: SettingsRoutes,
    allowedRole: [RoleNameEnum.manager, RoleNameEnum.superadmin],
  },
  {
    name: 'Выбор центра',
    path: AppRoutesPaths.ChoiseCenter,
    element: <ChoiseCenter />,
    allowedRole: [RoleNameEnum.manager, RoleNameEnum.superadmin],
  },
  {
    name: 'Админка',
    path: AppRoutesPaths.AdminPanel,
    element: <NavigationNavbar routes={AdminRoutes} title='Админка' />,
    allowedRole: [RoleNameEnum.superadmin],
    childs: AdminRoutes,
    icon: '/icons/AdminIcon.svg',
  },
  {
    name: 'Персонал',
    path: AppRoutesPaths.Staff,
    element: <StaffPage />,
    allowedRole: [RoleNameEnum.superadmin, RoleNameEnum.manager],
    icon: '/icons/staff.svg',
  },
  {
    name: 'Игры',
    path: AppRoutesPaths.Games,
    element: <GamesPage />,
    allowedRole: [RoleNameEnum.superadmin, RoleNameEnum.manager, RoleNameEnum.animator],
    icon: '/icons/Games.svg',
  },
  {
    name: 'Профиль',
    path: AppRoutesPaths.Profile,
    element: <EmployeesFormPage isProfile={true} />,
    allowedRole: [RoleNameEnum.superadmin, RoleNameEnum.manager, RoleNameEnum.animator],
  },
]

export type AppRoutesType = {
  name: string
  path: string
  icon?: string
  element: JSX.Element
  childs?: AppRoutesType
  allowedRole?: RoleNameEnum[]
}[]

export const AppRoutes: AppRoutesType = [
  {
    name: 'Root',
    path: AppRoutesPaths.Root,
    element: <RootPage></RootPage>,
    childs: [...NavigationMenuItems],
    allowedRole: [RoleNameEnum.default],
  },
]
