import { ApiPayment } from '@@types/apiLogicTypes'
import { UiButton } from '@components/ui/UiButton/UiButton'
import { ToastContext } from '@context/toastContext'
import { useLogic } from '@hooks/storeHook'
import { TicketPaymentFormType } from '@modules/Reception/types'
import { ConfirmPopup } from 'primereact/confirmpopup'
import { useContext, useRef, useState } from 'react'
import { useFormContext } from 'react-hook-form'

interface IManualApplyButton {
  retryCallback: (sum?: number, advancePayment?: number, credit?: number) => void
  payment: ApiPayment
}

export const ManualApplyButton = ({ retryCallback, payment }: IManualApplyButton) => {
  // const isOrderPrePaid = payment.order.status === 5

  const methods = useFormContext<TicketPaymentFormType>()
  const isPreOrderInclude = payment.sum < payment.order.price
  const logic = useLogic()
  const [visible, setVisible] = useState(false)
  const lastPayment = payment.order.payments.at(-1)
  const ctx = useContext(ToastContext)

  const buttonEl = useRef(null)

  const accept = async () => {
    const data = methods.getValues()
    const formData = new FormData()
    payment?.status === 1 && isPreOrderInclude && formData.append('is_prepayment', '1')
    console.log(data)
    const res = await logic.applyPayment(formData, lastPayment!.id ?? payment.id)
    if (res.status)
      retryCallback(
        lastPayment?.sum,
        payment.order.status === 5 ? payment!.sum : 0,
        isPreOrderInclude && payment.order.status !== 5 ? payment!.order.price - payment!.sum : 0
      )
    else ctx?.toastShowHandler({ status: false, errors: res.errors })
  }

  const reject = () => {
    setVisible(false)
  }

  const onClick = async () => {
    setVisible(true)
  }
  return (
    <>
      <ConfirmPopup
        target={buttonEl!.current!}
        visible={visible}
        onHide={() => setVisible(false)}
        message='Вы действительно хотите подтвердить платеж вручную?'
        icon='pi pi-exclamation-triangle'
        accept={accept}
        reject={reject}
        acceptLabel='Да'
        rejectLabel='Нет'
      />
      <div className='card flex justify-content-center' ref={buttonEl}>
        <UiButton
          color='red'
          onClick={onClick}
          type='button'
          disabled={
            lastPayment?.status === 2 ||
            lastPayment?.type === 0 ||
            (payment.order.pin_activated !== 1 && lastPayment?.type !== 7)
          }
        >
          Подтвердить последний платеж
        </UiButton>
      </div>
    </>
  )
}
