import { UiText } from '@components/ui/UiText/UiText'
import { MultiSelect, MultiSelectProps } from 'primereact/multiselect'
import { FC } from 'react'
import { Controller, useFormContext } from 'react-hook-form'
import './styles.css'

interface IFormMultiSelect extends MultiSelectProps {
  name: string
  title?: string
  customSize?: 'small' | 'full'
}

const FormMultiselect: FC<IFormMultiSelect> = ({ name, title, customSize, ...props }) => {
  const methods = useFormContext()

  return (
    <div className={`gap-[4px] flex flex-col ${customSize === 'small' ? 'w-[233px]' : 'w-full'}`}>
      {title && <UiText className='text-grey'>{title}</UiText>}
      <div
        className={`card flex justify-content-center ${
          customSize === 'small' ? 'min-w-[229px] max-w-[229px]' : 'w-full'
        }`}
      >
        <Controller
          control={methods.control}
          name={name}
          rules={props.required ? { required: 'Value is required.' } : undefined}
          render={({ field, fieldState }) => (
            <MultiSelect
              value={field.value}
              onChange={(e) => field.onChange(e.value)}
              optionLabel='name'
              placeholder='Выбрать шоу'
              display='chip'
              className={`w-full md:w-14rem border-green worker multiselect min-h-[36px] 
              ${customSize === 'small' ? 'min-w-[229px] max-w-[229px]' : 'w-full'}
              ${fieldState.error && 'incorrect'} 
            `}
              removeIcon={(props) => (
                <i
                  className='pi pi-times text-white text-xs !font-bold cursor-pointer'
                  onClick={props?.iconProps.onClick}
                ></i>
              )}
              panelClassName='multiselect-dropdown'
              // itemTemplate={renderItemTemplate}
              selectedItemsLabel={`Выбрано ${field?.value?.length}`}
              filter
              maxSelectedLabels={props.maxSelectedLabels ?? 3}
              itemClassName='!text-white'
              {...props}
            />
          )}
        />
      </div>
    </div>
  )
}

export default FormMultiselect
