import { UiText } from '@components/ui/UiText/UiText'
import { Checkbox } from 'primereact/checkbox'
import { Controller, useFormContext } from 'react-hook-form'

interface IFormCheckbox {
  name: string
  title: string
}

export const FormCheckbox = ({ name, title }: IFormCheckbox) => {
  const { control } = useFormContext()

  return (
    <div className='flex gap-3 items-center'>
      <Controller
        name={name}
        control={control}
        render={({ field }) => (
          <Checkbox checked={Boolean(field.value)} onChange={(e) => field.onChange(e.checked)} inputRef={field.ref} />
        )}
      />
      <UiText className='text-grey'>{title}</UiText>
    </div>
  )
}
